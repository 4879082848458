import React from "react"
import styles from "../styles/aboutme.module.css"

export default () => {
    return (
            <div className={styles.aboutMeContainer}>
                <div className={styles.aboutMeHeading}> About </div>
                <div className={styles.aboutMeContent + " lead"}>
                    <p>
                        I studied Physics at the University of California, Santa Barbara (Class of 2013).
                    </p>
                    <p>
                        In 2014 I moved to San Diego and began working at biotech company Integrated DNA Technologies as a production scientist.
                    </p>
                    <p>
                        In 2019 I moved to New York City to work at the New York Stem Cell Foundation as a programmer.
                    </p>
                    <p>
                        I learned to program when I was 17 and have been builing things ever since. I started this website to keep track of the things that I've made.
                    </p>
                    <p>
                        The best way to get in touch with me is by emailing me at <a href="mailto:whitedann@gmail.com">whitedann@gmail.com</a>.
                    </p>
                </div>
            </div>
    )
}